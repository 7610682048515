@import '../../mixins';

.products {
  & .product-card {
    width: 100%;

    &>form {
      @include hover {
        box-shadow: none;
      }
    }
  }

  & .swiper {
    @include media(#{rem(749)}) {
      overflow: visible;
    }

    &-slide {
      @include media(#{rem(749)}) {
        width: rem(239);
      }
    }
  }
}
